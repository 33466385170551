.logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: App-logo-spin infinite 4s linear;
  }
}

@keyframes App-logo-spin {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: translateX(-70px) rotate(-10deg);
  }
  20% {
    transform: translateX(-70px) rotate(10deg);
  }
  30% {
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    transform: translateX(0px) rotate(0deg);
  }
}
